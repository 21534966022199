<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <b-img
        :src="appLogoImage"
        alt="logo"
        center
        width="200px"
        class="mb-5"
      />
      <div class="w-100 text-center">
        <div v-if="!hasError">
          <h2 class="mb-1">
            Waiting... We will redirect you to the asset information page
          </h2>
        </div>

        <div v-else>
          <div v-if="assetNotFound">
            <h2 class="mb-1">
              Could not find asset
            </h2>
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              :to="{path:'/'}"
            >
              Back to home
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

import AssetService from '@/service/asset.service'

export default {
  components: {
    BImg,
    BButton,
  },
  data() {
    return {
      assetNotFound: false,
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app

    return {
      appLogoImage,
    }
  },
  computed: {
    systemNumber() {
      return this.$route.params.systemnumber
    },
    shouldAutoRedirectToAsup() {
      return !!this.$route.query.ar
    },
    hasError() {
      return this.assetNotFound
    },
  },
  mounted() {
    AssetService.getBySystemNumberAsync(this.systemNumber, { disableTenantFilter: true })
      .then(result => {
        if (this.shouldAutoRedirectToAsup) {
          this.redirectToAsup(result.id)
        } else {
          this.redirectToAsset(result.id)
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch(e => {
        this.assetNotFound = true
      })
  },
  methods: {
    redirectToAsset(assetId) {
      const routeParams = { id: assetId }
      this.$router.replace({ name: 'asset-detail-page', params: routeParams })
    },
    redirectToAsup(assetId) {
      const routeParams = { id: assetId }
      this.$router.replace({ name: 'asset-to-asup-redirect', params: routeParams })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
